export const useQsPopupStore = defineStore('qs/popup', () => {
  const isOpen = ref(false);

  const open = () => {
    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  return {
    close,
    isOpen,
    open,
  };
});
